<template>
  <div class="container">
   
    <Header></Header>


    <div class="faq-main">
     
        <h3 class="ny-title text-center">FAQ</h3>

        <div class="faq-con">

          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :title="item.name" :name="index" v-for="(item,index) in model" :key="index">
              <div class="faq-list-text" v-html="item.content"></div>
            </el-collapse-item>
            
          </el-collapse>

        </div>
     
    </div>
    <!-- main end  -->
     <Footer></Footer>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { list } from '@/api/faq'


export default {
  components: {
    Header,
    Footer,
  
  },
  data() {
    return {
      model: [],
      activeNames: ['1'],
    };
  },
 created() {
    this.faqlist()
  },
 
  methods: {
    faqlist(){
	  list().then(res => {
	    //alert(JSON.stringify(res.data.data.list))
        this.model=res.data.data.list
      }).catch(() => {
      })
	},
    clickLink () {
      this.$router.push({path:'/home'});

    },

    handleChange(val) {
      console.log(val);
    }
      
   
    



  },
}
</script>

<style scoped >


.faq-main{
  overflow: hidden;
  padding: 50px 8.3vw;
}

.ny-title{
  color: #0a3c5b;
  line-height: 1;
  font-size: 6.25vw;
  font-family: DINCound;
  font-weight: normal;
}

.faq-con{
  width: 100%;
  margin-top: 35px;
}

.el-collapse{
  border-top: 0;
  border-bottom: 0;
}

.faq-con /deep/ .el-collapse-item__header,
.faq-con /deep/ .el-collapse-item__wrap{
  background: transparent !important;
  border-color: #dcdde1;
  
}

.faq-con /deep/ .el-collapse-item__header{
  font-size: 22px;
  color: #000;
  padding: 20px 0;
  line-height: 28px;
  height: auto;
  min-height: 28px;
}

.faq-con /deep/ .el-collapse-item__content{
  font-size: 20px;
  color: #666;
}

.faq-list-text{
 margin-top: 25px;
}

.faq-con /deep/ .el-collapse-item:last-child .el-collapse-item__header{
  border-bottom: 0;
}

.faq-con /deep/ .el-collapse-item__arrow{
  transform: rotate(90deg);
}

.faq-con /deep/ .el-collapse-item__arrow.is-active{
  transform: rotate(270deg);
}

</style>
